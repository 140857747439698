import React from "react"
import { graphql } from 'gatsby'
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Article from "../components/article"

const ArticleList = ({ data, pageContext }) => {

const ArticleItems = data.allContentfulArticle.edges

const { currentPage, allArticles, numPagesArticles, allTopicsArray, allTopicsSlugs, skip, limit } = pageContext
const isFirst = currentPage === 1
const isLast = currentPage === numPagesArticles
const prevPage = currentPage - 1 === 1 ? "" : (currentPage - 1).toString()
const nextPage = (currentPage + 1).toString()

  return (
    <Layout
      pageHeading="Articles"
      subHeading="Articles from around the web on all things user experience, sorted chronologically."
    >
      <SEO
        title="All Articles | SpotlightUX"
        description="Articles from around the web on all things user experience."
        body="article"
      />
      <div className="layout-wrapper">
        <div className="flex">
          <main>
            <div className="my-container">
              <h2>
              {currentPage ===  1
                ? `${allArticles} Articles`
                : skip + limit < allArticles ? `Page ${currentPage} (${skip + 1} \u2013 ${skip + limit} of ${allArticles} Articles)` : `Page ${currentPage} (${skip + 1} \u2013 ${allArticles} of ${allArticles} Articles)`
              }</h2>
              <div className="list-wrapper">
                {ArticleItems.map(({ node }) =>
                    <Article
                      key={node.id}
                      date={node.date}
                      description={node.description.description}
                      source={node.source}
                      title={node.title}
                      articleLink={node.link}
                      author={node.author}
                    />
                )}
              </div>
              <div className="pagination">
                <span>
                {!isFirst && (
                  <Link to={currentPage === 2 ? `/articles/` : `/articles/page/${prevPage}/`} className="button">Previous Page</Link>
                )}
                </span>
                <span>
                {!isLast && (
                  <Link to={`/articles/page/${nextPage}/`} className="button">Next Page</Link>
                )}
                </span>
              </div>
            </div>
          </main>
          <aside className="topics">
            <h2>Topics</h2>
            <ul>
            {allTopicsArray.map((topic, i) => {
              return <li key={i}><Link to={`/topic/${allTopicsSlugs[i]}/`}>{topic}</Link></li>
            })}
            </ul>
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query ArticleQuery($skip: Int!, $limit: Int!) {
  allContentfulArticle(
    sort: {order: DESC, fields: date}
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        author
        source
        link
        id
        date(formatString: "MMMM D, YYYY")
        description {
          description
        }
        title
      }
    }
  }
}
`

export default ArticleList
